<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <h1 class="static__page__title">{{title}}</h1>
      <div class="itemBox no-ma-top">
        <h2 class="boxTitle">個人情報の収集および利用について</h2>
        <div class="itemBoxInner static__txt">
          <p>
             個人情報とは、特定の個人を特定する、または特定の個人に連絡するために利用できるデータのことを指します。当サイトでは、個人情報の収集および利用することはございませんのでご安心ください。
          </p>
          <p>
           当サイトを利用するユーザー（以下、ユーザー）は、これら（一部あるいは全部を問わず）を、複製、公開送信、頒布、譲渡、貸与、翻訳、翻案、使用許諾、転載、再用利用しないことに同意するものとします。
          </p>
        </div>
        <h2 class="boxTitle">非個人情報の収集および利用について</h2>
        <div class="itemBoxInner static__txt">
          <p>
            当社が扱う情報には、そのままでは特定の個人と直接関連付けられることのない非個人情報があります。当社は、様々な目的で、非個人情報を収集、利用、移転および開示することがあります。以下は、当社が収集する非個人情報の種類およびその利用方法の例です。
          </p>
        </div>
        <h2 class="boxTitle">Cookie(クッキー)、その他の技術の利用について</h2>
        <div class="itemBoxInner static__txt">
          <p>
            当サイトでは、「Cookie」やその他の技術を利用し、ユーザーがサイトを利用する際の利便性の向上や、当社がユーザの行動をより良く理解するために非個人情報を利用しています。例えば、お気に入り情報や、広告などがそれにあたります。これらの情報で個人を特定することは出来ません。
          </p>
          <p>
            また、一部広告については、当該第三者が、当サイトを訪問したユーザーのCookie情報等を取得することがあります。ここで取得されたクッキー情報等は、当該第三者のプライバシーポリシーに従って取り扱われるため、弊社の管理管轄外になります。
          </p>
        </div>
        <h2 class="boxTitle">継続的改善について</h2>
        <div class="itemBoxInner static__txt">
          <p>
            当社は、当サイトを利用するユーザーの利便性や当社の運営状況を鑑みて、適宜個人情報の取扱いを見直し、継続的な改善に努めます。その上で、必要に応じて本プライバシーポリシーを変更することがあります。
          </p>
        </div>
        <p class="static__txt outer">2013年10月1日制定 2016年3月1日改訂</p>
      </div>
    </section>
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";

export default {
  data() {
    return {
      HeadSetting: {
        Left: 'backBtn',
        Right: 'mypage',
        Center: 'search'
      },
      title: 'プライバシーポリシー',
      description: 'プライバシーポリシーページ。当サイトを利用するにあたって、確認して頂きたい個人情報、非個人情報について説明しています。'
    };
  },
  components: {
    Head,
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
}
</script>

<style scoped>
.static__page__title{
  text-align: center;
  margin: 32px 0;
}

.item__detail--spec--table dt{
  width: 30%;
}

.static__txt{
  font-size: .85rem;
}

.static__txt.outer{
  padding: 16px;
}
</style>
